export default {
  register: '[USER] Register',
  login: '[USER] Login',
  logout: '[USER] Logout',
  save: '[USER] Save',
  getUser: '[USER] Get user',
  getAvailableWithdrawals: '[USER] Get available withdrawals',
  createWithdrawal: '[USER] Create withdrawal',
  detachCard: '[USER] Detach card',
  getBills: '[USER] Get bills',
  payByToken: '[USER] Pay by token',
  createInvoice: '[USER] Create invoice',
  checkInvoice: '[USER] Check invoice',
}
