import mutationTypes from './mutationTypes'
import initState from './state'

export default {
  [mutationTypes.setUser](state, payload) {
    state.user = payload
  },
  [mutationTypes.removeUser](state) {
    state = initState()
  },
  [mutationTypes.toggleSmooth](state, payload) {
    state.smooth = payload
  },
  [mutationTypes.setTariff](state, payload) {
    state.user.tariff = payload
  },
  [mutationTypes.setWithdrawals](state, { availableWithdrawals, withdrawalPayments, totalWithdrawals }) {
    state.availableWithdrawals = availableWithdrawals
    state.withdrawalPayments = withdrawalPayments
    state.totalWithdrawals = totalWithdrawals
  },
  [mutationTypes.saveHistory](state, payload) {
    state.user.info.history.push(payload)
  },
  [mutationTypes.clearHistory](state, payload) {
    state.user.info.history = state.user.info.history.filter((item) => item !== payload)
  },
}
