import { notification } from 'ant-design-vue'
import store from '@/store'

export default function (
  e,
  { message, description } = {
    message: e?.response?.data?.message || e?.message || 'Что-то пошло не так, напишите в техподдержку',
    description: e?.response?.data?.description || null,
  },
) {
  if (e?.response?.status === 401) {
    localStorage.removeItem('AccessToken')
  }
  notification.error({
    message,
    description,
  })
  store.commit('setLoading', false)
}
