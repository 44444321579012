import store from '@/store'
import router from '@/router'
import actionTypes from '@/store/user/actionTypes'

export default async (from, to, next) => {
  store.commit('setLastRoutName', to.name)

  if (from.name === 'auth' && from.params?.token) {
    localStorage.setItem('AccessToken', JSON.stringify(from.params?.token))
    if (!store.state.userStore.user?.id) await store.dispatch(actionTypes.getUser)
    return next('/')
  }

  const { AccessToken } = localStorage
  if ((!AccessToken || AccessToken === 'undefined' || !store.state.userStore.user?.id) && from.path !== '/login') {
    await router.push('/login')
    next(false)
  } else {
    next()
  }
}
