import axios from 'axios'
import errorHandler from '../../helpers/errorHandler'
import mutationTypes from './mutationTypes'
import actionTypes from './actionTypes'

export default {
  [actionTypes.getWithdrawals]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('admin/withdrawals')
        .then(({ data }) => {
          commit(mutationTypes.setWithdrawals, data)
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.getAccess]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('admin/access')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.sendWithdrawal]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('admin/withdrawals', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}
