export default {
  typesTips: 'https://wowtion.notion.site/f273afe2f8de4426a0e4a35ccace9fd3',
  settingTips: 'https://wowtion.notion.site/4fded8e2a71848cf945bc4c5d2d8f2a4',
  payments: 'https://wowtion.notion.site/4fded8e2a71848cf945bc4c5d2d8f2a4',
  contract: 'https://wowtion.pro/oferta.html',
  tariffs: 'https://wowtion.pro/#tariffs',
  settings: 'https://wowtion.notion.site/4fded8e2a71848cf945bc4c5d2d8f2a4',
  terms: 'https://wowtion.pro/oferta.html',
  privacyPolice: 'https://wowtion.pro/privacy.html',
  support: 'support@wowtion.pro',
  info: 'info@wowtion.pro',
}
