import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import './styles/variables.css'
import './styles/transitions.css'
import './styles/reset.css'
import './styles/fonts.css'
import './styles/global.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import focus from './directives/focus'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.locale('ru')
dayjs.extend(localizedFormat)

createApp(App)
  .use(axios)
  .use(store)
  .use(Antd)
  .use(router)
  .directive('focus', focus)
  .mount('#app')
