import axios from 'axios'
import notice from '@/helpers/notice'
import userActionTypes from '@/store/user/actionTypes'
import store from '@/store'
import ls from '@/helpers/ls'

export default {
  checkBill: (billId) => axios.get(`payments/bills/${billId}`),
  onSuccessCallback: async () => {
    store.commit('setLoading', true)
    await new Promise((resolve) => setTimeout(() => resolve(), 1000))
    notice({
      content: 'Оплата прошла успешно!',
      type: 'success',
    })

    await store.dispatch(userActionTypes.getUser)
    ls.remove('billId')
    store.commit('setLoading', false)
  }
}
