export default {
  setType: '[WOW] Set type',
  setPaymentSettings: '[WOW] Set payment settings',
  setPaymentType: '[WOW] Set payment type',
  setTariff: '[WOW] Set tariff',
  setWowtion: '[WOW] Set wowtion',
  updateWowtion: '[WOW] Update wowtion',
  updateWowtionInList: '[WOW] Update wowtion in list',
  setWowtionList: '[WOW] Set wowtion list',
  addLot: '[WOW] Add lot',
  setLot: '[WOW] Set lot',
  removeLot: '[WOW] Remove lot',
  removeWowtion: '[WOW] Remove wowtion',
  updateWowtionEndTime: '[WOW] Update wowtion end time',
  resetDates: '[WOW] Reset all dates',
  setErrors: '[WOW-UNTRACKED] Set Errors',
  setWinners: '[WOW-UNTRACKED] Set winners',
  setLeads: '[WOW-UNTRACKED] Set leads',
  removeLead: '[WOW-UNTRACKED] Remove lead',
}
