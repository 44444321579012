import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Page404 from '../views/Page404.vue'
import Cabinet from '../components/auth/Cabinet.vue'
import Payments from '../components/auth/Payments.vue'
import Login from '../views/Login.vue'
import Wowtion from '../components/wowtion/Wowtion.vue'
import Preview from '../components/wowtion/Preview.vue'
import List from '../components/wowtion/List.vue'
import Archive from '../components/wowtion/Archive.vue'
import ConstructorSettings from '../components/wowtion/constructor/ConstructorSettings.vue'
import Type from '../components/wowtion/constructor/Type.vue'
import Template from '../components/wowtion/constructor/Template.vue'
import Lots from '../components/wowtion/constructor/Lots.vue'
import Lot from '../components/wowtion/constructor/Lot.vue'
import Bonus from '../components/wowtion/constructor/Bonus.vue'
import LotsSale from '../components/wowtion/constructor/LotsSale.vue'
import Launch from '../components/wowtion/launch/Launch.vue'
import PaymentsTerms from '../components/wowtion/constructor/PaymentsTerms.vue'
import Dates from '../components/wowtion/constructor/Dates.vue'
import Form from '../components/wowtion/constructor/Form.vue'
import Live from '../components/wowtion/Live.vue'
import Leads from '../components/wowtion/Leads.vue'
import Contacts from '../components/wowtion/constructor/Contacts.vue'
import RegistrationPage from '../components/wowtion/constructor/RegistrationPage.vue'
import OpenGraph from '../components/wowtion/constructor/OpenGraph.vue'
import Tariffs from '../components/bill/Tariffs.vue'
import wowtionRouterHandler from './wowtionRouterHandler'
import userRouterHandler from './userRouterHandler'
import adminRouterHandler from './adminRouterHandler'
import auth from '@/router/middleWares/auth'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: userRouterHandler,
    children: [
      {
        path: '/cabinet',
        name: 'Cabinet',
        component: Cabinet,
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments,
      },
      {
        path: '/tariffs',
        name: 'Tariffs',
        component: Tariffs,
      },
      {
        path: '/',
        name: 'List',
        component: List,
      },
      {
        path: '/archive',
        name: 'Archive',
        component: Archive,
      },
      {
        path: '/leads',
        name: 'Leads',
        component: Leads,
      },
      {
        path: '/:id',
        name: 'Wowtion',
        component: Wowtion,
        beforeEnter: wowtionRouterHandler,
        children: [
          {
            path: '/:id',
            name: 'Preview',
            component: Preview,
            meta: {
              module: 'Preview',
            },
          },
          {
            path: '/:id/template',
            name: 'Template',
            component: Template,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/type',
            name: 'Type',
            component: Type,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/lots-sale',
            name: 'LotsSale',
            component: LotsSale,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/launch',
            name: 'Launch',
            component: Launch,
          },
          {
            path: '/:id/payments-terms',
            name: 'PaymentsTerms',
            component: PaymentsTerms,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/dates',
            name: 'Dates',
            component: Dates,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/form',
            name: 'Form',
            component: Form,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/contacts',
            name: 'Contacts',
            component: Contacts,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/registration-page',
            name: 'RegistrationPage',
            component: RegistrationPage,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/bonuses',
            name: 'Bonuses',
            component: Bonus,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/open-graph',
            name: 'OpenGraph',
            component: OpenGraph,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/constructor-settings',
            name: 'ConstructorSettings',
            component: ConstructorSettings,
            meta: {
              module: 'constructor',
            },
          },
          {
            path: '/:id/live',
            name: 'Live',
            component: Live,
          },
          {
            path: '/:id/lots',
            name: 'Lots',
            component: Lots,
            meta: {
              module: 'constructor',
            },
            children: [
              {
                path: '/:id/lots/:lotId',
                name: 'Lot',
                component: Lot,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/:token',
    name: 'auth',
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter: adminRouterHandler,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: Page404,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach(auth)

export default router
