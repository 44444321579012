import store from '@/store'
import actionTypes from '@/store/user/actionTypes'
import ls from '@/helpers/ls'
import cp from '@/utils/cp'

export default async (options, onSuccess) => {
  try {
    const { billId } = await store.dispatch(actionTypes.createInvoice, options)

    await cp({ ...options, billId }, async () => {
      ls.set('billId', billId)
      const { status } = await store.dispatch(actionTypes.checkInvoice, { paymentId: billId })
      if (status === 'success') await onSuccess()
    })
  } catch (e) {
    console.log(e)
  }
}
