import { useRoute, useRouter } from 'vue-router'

export const requiredRoutesNames = ['Type', 'PaymentsTerms', 'Lots', 'Form', 'Contacts', 'Launch']

export function useTipRouter() {
  const route = useRoute()
  const router = useRouter()

  const next = () => {
    const index = requiredRoutesNames.indexOf(route.name)
    if (index === -1) {
      if (route.name === 'Lot') return router.push({name: 'Form'})
      else return router.push({name: 'ConstructorSettings'})
    }
    router.push({ name: requiredRoutesNames[index + 1] })
  }

  const back = () => {
    const index = requiredRoutesNames.indexOf(route.name)
    if (index > 0) router.push({ name: requiredRoutesNames[index - 1] })
    else router.push({ name: 'PaymentsTerms' })
  }

  return {
    next, back
  }
}

