export default {
  setUser: '[USER] Set user',
  removeUser: '[USER] Remove user',
  toggleSmooth: '[USER] Toggle smooth animation',
  setTariff: '[USER] Set tariff',
  detachCard: '[USER] Detach card',
  setWithdrawals: '[USER-UNTRACKED] Set available setWithdrawals',
  saveHistory: '[USER-UNTRACKED] Save user history',
  clearHistory: '[USER-UNTRACKED] Clear user history',
}
