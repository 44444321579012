import store from '../store/index'
import actionTypes from '@/store/user/actionTypes'

export default async function (from, to, next) {
  if (from.name !== 'login' || from.name !== 'register') {
    const accessToken = localStorage.getItem('AccessToken')
    if (accessToken && accessToken !== 'undefined') {
      try {
        await store.dispatch(actionTypes.getUser)
      } catch (e) {
        return next({ name: 'Login' })
      }
    }
    if (!store.state.userStore.user?.id) return next({ name: 'Login' })
    else return next()
  } else return next()
}
