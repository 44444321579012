import axios from 'axios'
import errorHandler from '../../helpers/errorHandler'
import mutationTypes from './mutationTypes'
import actionTypes from './actionTypes'

export default {
  [actionTypes.create]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post('wowtions')
        .then(({ data }) => {
          commit(mutationTypes.setWowtion, data?.wowtion)
          resolve(data?.wowtion?.id)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.check]({ commit }, wowtionId) {
    return new Promise((resolve) => {
      axios
        .post(`wowtions/${wowtionId}/check`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.launch]({ commit }, wowtionId) {
    return new Promise((resolve) => {
      axios
        .post(`wowtions/${wowtionId}/launch`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.unlock]({ commit }, wowtionId) {
    return new Promise((resolve) => {
      axios
        .post(`wowtions/${wowtionId}/unlock`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.save]({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.wowtion?.blocked || !state.wowtion?.enable) {
        commit('setWowtionStatus', false)
        errorHandler(null, {
          message: 'Редактирование невозможно!',
          description: `${state.wowtion?.enable ? 'Остановите аукцион в разделе "Запуск"' : 'Аукцион закрыт'}`,
        })
      } else {
        axios
          .patch('wowtions', { ...state.wowtion })
          .then(() => {
            commit(mutationTypes.updateWowtionInList)
            resolve()
          })
          .catch((e) => {
            errorHandler(e)
            reject()
          })
          .finally(() => {
            commit('setWowtionStatus', false)
            commit('setLoading', false)
          })
      }
    })
  },
  [actionTypes.getWowtions]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('wowtions')
        .then(({ data }) => {
          commit(mutationTypes.setWowtionList, data.list)
          resolve(data.list)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.getLeads]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('wowtions/leads')
        .then(({ data }) => {
          commit(mutationTypes.setLeads, data)
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.getLeadsByWowtionId]({ commit }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`wowtions/leads/${wowtionId}`)
        .then(({ data }) => {
          commit(mutationTypes.setLeads, data)
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.removeLead]({ commit }, leadId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`wowtions/leads/${leadId}`)
        .then(({ data }) => {
          if (data.status === 'ok') {
            commit(mutationTypes.removeLead, leadId)
          }
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.getArchivesWowtions]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('wowtions/archive')
        .then(({ data }) => {
          commit(mutationTypes.setWowtionList, data.list)
          resolve(data.list)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.getWowtion]({ commit }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`wowtions/${wowtionId}`)
        .then(({ data }) => {
          commit(mutationTypes.setWowtion, data)
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e, { message: 'Аукцион не найден' })
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.getWinners]({ commit }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`wowtions/${wowtionId}/winners`)
        .then(({ data }) => {
          commit(mutationTypes.setWinners, { winners: data, wowtionId })
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e, { message: 'Аукцион не найден' })
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.deleteWowtion]({ commit }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`wowtions/${wowtionId}`)
        .then(() => {
          commit(mutationTypes.removeWowtion, wowtionId)
          resolve()
        })
        .catch((e) => {
          if (e.response?.data?.message === 'Blocked')
            errorHandler(null, {
              message: 'Ваш аукцион запущен, удаление невозможно!',
              description: 'Остановите аукцион в разделе "Запуск"',
            })
          else errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.archiveWowtion]({ commit }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`wowtions/${wowtionId}/archive`)
        .then(() => {
          commit(mutationTypes.removeWowtion, wowtionId)
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.copyWowtion]({ commit, dispatch }, wowtionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`wowtions/${wowtionId}/copy`)
        .then(() => {
          dispatch(actionTypes.getWowtions)
            .then(() => {
              resolve()
            })
            .catch((e) => {
              errorHandler(e)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}
