import store from '../store/index'
import mutationTypes from '../store/wowtion/mutationTypes'
import actionTypes from '../store/wowtion/actionTypes'

// set the correct wowtion looking at the id from url
const hasLot = (from) => {
  const lots = store.state.wowtionStore.wowtion?.lots

  if (lots.length) {
    const lot = lots.find((lot) => lot.id === from.params.lotId)
    return !!lot
  } else return false
}

const isSetWowtion = async (from) => {
  if (store.state.wowtionStore.wowtion?.id && from.params.id === store.state.wowtionStore.wowtion?.id) return true
  if (!store.state.wowtionStore?.wowtionList.length) {
    try {
      const wowtion = await store.dispatch(actionTypes.getWowtion, from.params.id)
      return !!wowtion
    } catch (e) {
      return false
    }
  } else {
    const wowtion = store.state.wowtionStore?.wowtionList.find((wowtion) => wowtion.id === from.params.id)
    if (wowtion) {
      store.commit(mutationTypes.setWowtion, wowtion)
      return true
    }
    return false
  }
}

export default async function (from, to, next) {
  let success

  success = await isSetWowtion(from)
  if (!success) return next({ name: 'List' })

  if (from.name === 'Lot') {
    success = await hasLot(from)
    if (!success) return next({ name: 'Lots', params: { id: from.params.id } })
  }

  return next()
}
