import axios from 'axios'
import store from '@/store'

const upload = (formData) => {
  return axios.post(`wowtions/${store.state.wowtionStore.wowtion?.id}/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const destroy = (id) => {
  if (!id) return Promise.resolve()
  return axios.delete(`wowtions/${store.state.wowtionStore.wowtion?.id}/image`, {
    data: { id },
  })
}

export { upload, destroy }
