import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import mutationHandler from '../plugins/mutationHandler'
import userStore from './user/'
import wowtionStore from './wowtion/'
import adminStore from './admin/'

export default createStore({
  state: {
    locale: 'ru',
    userChanged: false,
    wowtionChanged: false,
    lastRoutName: '',
    loading: false,
  },
  mutations: {
    setLocale(state, payload) {
      state.locale = payload
    },
    setUserStatus(state, payload) {
      state.userChanged = payload
    },
    setWowtionStatus(state, payload) {
      state.wowtionChanged = payload
    },
    setLastRoutName(state, payload) {
      state.lastRoutName = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
  },
  actions: {},
  modules: { userStore, wowtionStore, adminStore },
  plugins: [
    createPersistedState({
      paths: ['userStore'],
    }),
    mutationHandler(),
  ],
})
