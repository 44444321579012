import { computed } from 'vue'
import actionTypes from '@/store/user/actionTypes'
import mutationTypes from '@/store/user/mutationTypes'
import store from '@/store'

const history = computed(() => store.state.userStore?.user?.info?.history || [])

const add = async (payload) => {
  if (history.value.includes(payload)) return
  store.commit(mutationTypes.saveHistory, payload)
  await store.dispatch(actionTypes.save)
}

const remove = async (payload) => {
  if (!history.value.includes(payload)) return
  store.commit(mutationTypes.clearHistory, payload)
  await store.dispatch(actionTypes.save)
}

const has = (payload) => {
  return history.value.includes(payload)
}

export default { add, remove, has }
