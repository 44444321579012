// при любом изменении изменять так же в api

const tariffs = [
  {
    title: '🙈❤😉',
    tariff: 1,
    amount: 30,
    priceRu: '49.00',
    commission: 7,
    infinity: false,
  },
  {
    title: '🙏😍🙏',
    tariff: 2,
    amount: 70,
    priceRu: '99.00',
    commission: 7,
    infinity: false,
  },
  {
    title: '🥳😎🤩',
    tariff: 3,
    amount: 150,
    priceRu: '199.00',
    commission: 7,
    infinity: false,
  },
  {
    title: '😇🤓💰',
    tariff: 4,
    amount: 500,
    priceRu: '699.00',
    commission: 7,
    infinity: false,
  },
  {
    title: '😇💰🤓',
    tariff: 5,
    amount: 1000,
    priceRu: '999.00',
    commission: 7,
    infinity: false,
  },
  {
    title: '💰😇💰',
    tariff: 6,
    amount: 2000,
    priceRu: '1699.00',
    commission: 7,
    infinity: false,
  }
]

export const commissionPercentage = 90
export const commissionSell = 8

export const getTariff = (tariff) => {
  return tariffs.find((tariffItem) => tariffItem.tariff === tariff)
}
export const getTotalCost = (cost) => (cost * commissionPercentage) / 100

export const getTariffByAmount = (amount) => {
  return tariffs.find((tariffItem) => tariffItem.priceRu === amount)
}

export default tariffs
