import store from '../store/index'
import actionTypes from '@/store/admin/actionTypes'

export default async function (from, to, next) {
  try {
    const { status } = await store.dispatch(actionTypes.getAccess)
    if (status === 'ok') return next()
    else return next({ name: 'List' })
  } catch (e) {
    return next({ name: 'List' })
  }
}
