import axios from 'axios'
import errorHandler from '../../helpers/errorHandler'
import mutationTypes from './mutationTypes'
import actionTypes from './actionTypes'

export default {
  [actionTypes.register]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('users/register', { ...payload })
        .then(({ data }) => {
          localStorage.setItem('AccessToken', JSON.stringify(data.token))
          delete data.token
          commit(mutationTypes.setUser, data)
          resolve()
        })
        .catch((e) => {
          if (e.response.data.code === '11000') errorHandler({ message: 'Пользователь с такой почтой уже зарегистрирован' })
          else errorHandler(e)
        })
    })
  },
  [actionTypes.getUser]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('users/profile')
        .then(({ data }) => {
          commit(mutationTypes.setUser, data.user)
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.login]({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('users/login', { ...payload })
        .then(({ data }) => {
          localStorage.setItem('AccessToken', JSON.stringify(data.token))
          commit(mutationTypes.setUser, data.user)
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
        })
    })
  },
  [actionTypes.logout]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post('users/logout')
        .then(() => {
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          localStorage.removeItem('AccessToken')
          commit(mutationTypes.removeUser)
          window.location.reload()
        })
    })
  },
  // [actionTypes.detachCard]({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post('detach-card')
  //       .then(() => {
  //         resolve()
  //       })
  //       .catch((e) => {
  //         errorHandler(e)
  //         reject()
  //       })
  //   })
  // },
  [actionTypes.getBills]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('payments/bills')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.getAvailableWithdrawals]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('payments')
        .then(({ data }) => {
          commit(mutationTypes.setWithdrawals, data)
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  [actionTypes.payByToken]({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('payments/charge', { amount: payload.tariff.priceRu, wowtionId: payload.wowtionId })
        .then(() => {
          resolve()
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.createWithdrawal]() {
    return new Promise((resolve, reject) => {
      axios
        .post('payments/withdrawal')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.save]({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .patch('users', { ...state.user })
        .then(() => resolve())
        .catch((e) => {
          errorHandler(e)
          reject()
        })
        .finally(() => commit('setUserStatus', false))
    })
  },
  //TODO вынессти в утилиту
  [actionTypes.createInvoice]({ commit, state }, invoice) {
    return new Promise((resolve, reject) => {
      axios
        .post('payments', invoice)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
  [actionTypes.checkInvoice]({ commit, state }, invoice) {
    return new Promise((resolve, reject) => {
      axios
        .post('payments/status', invoice)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          errorHandler(e)
          reject()
        })
    })
  },
}
