import { message } from 'ant-design-vue'

/**
 *
 * @param message
 * @param description
 * @param duration
 * @param {string} [type=info]
 */
export default function ({ content, duration, type = 'info' }) {
  message[type]({
    content,
    duration,
  })
}
