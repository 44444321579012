const initState = () => {
  return {
    user: {},
    smooth: true,
    availableWithdrawals: {},
    withdrawalPayments: {},
  }
}

export default initState
