import wowtionMutationTypes from '../store/wowtion/mutationTypes'
import userMutationTypes from '../store/user/mutationTypes'
const EXCLUDED_WOWTIONS_TYPES = [wowtionMutationTypes.setWowtionList, wowtionMutationTypes.setWowtion]
const EXCLUDED_USERS_TYPES = [userMutationTypes.setUser, userMutationTypes.setTariff]

export default () => {
  return (store) => {
    store.subscribe((mutation) => {
      if (mutation.type.startsWith('[WOW]') && !EXCLUDED_WOWTIONS_TYPES.includes(mutation.type) && store.state.wowtionChanged === false) {
        store.commit('setWowtionStatus', true)
      }

      if (mutation.type.startsWith('[USER]') && !EXCLUDED_USERS_TYPES.includes(mutation.type) && store.state.userChanged === false)
        store.commit('setUserStatus', true)
    })
  }
}
