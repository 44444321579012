import mutationTypes from './mutationTypes'
import { v4 as uuidv4 } from 'uuid'
import { last } from '@/helpers/array'

export default {
  [mutationTypes.setWowtion](state, payload) {
    state.wowtion = payload
  },
  [mutationTypes.updateWowtion](state, payload) {
    state.wowtion = payload
  },
  [mutationTypes.updateWowtionInList](state) {
    state.wowtionList.some((item, index) => {
      if (item.id === state.wowtion.id) {
        state.wowtionList.splice(index, 1, state.wowtion)
        return true
      } else return false
    })
  },
  //TODO set init value
  removeWowtion(state) {
    state.wowtion = null
  },
  [mutationTypes.setWowtionList](state, payload) {
    state.wowtionList = payload
  },
  [mutationTypes.setErrors](state, { wowtionId, errors }) {
    state.errors[wowtionId] = errors
  },
  [mutationTypes.setWinners](state, { wowtionId, winners }) {
    state.winners[wowtionId] = winners
  },
  [mutationTypes.setType](state, type) {
    state.wowtion.type = type
  },
  [mutationTypes.setPaymentSettings](state, payload) {
    state.wowtion.paymentSettings = payload
  },
  [mutationTypes.setPaymentType](state, payload) {
    state.wowtion.paymentSettings.type = payload
  },
  [mutationTypes.addLot](state) {
    state.wowtion.lots.push({ id: uuidv4() })
  },
  [mutationTypes.removeLot](state, payload) {
    state.wowtion.lots = state.wowtion.lots.filter((lot) => lot.id !== payload)
  },
  [mutationTypes.removeWowtion](state, payload) {
    state.wowtionList = state.wowtionList.filter((wowtion) => wowtion.id !== payload)
  },
  [mutationTypes.setLot](state, payload) {
    const index = state.wowtion.lots.findIndex((lot) => lot.id === payload.lotId)
    state.wowtion.lots[index] = payload.lot
  },
  [mutationTypes.updateWowtionEndTime](state) {
    const lastLot = last(state.wowtion.lots)
    if (lastLot) state.wowtion.end = lastLot.end
  },
  [mutationTypes.setLeads](state, payload) {
    state.leads = payload
  },
  [mutationTypes.removeLead](state, payload) {
    state.leads = state.leads.filter((lead) => lead.id !== payload)
  },
  [mutationTypes.resetDates](state) {
    const wowtion = state.wowtion
    wowtion.start = null
    wowtion.startRegistration = null
    wowtion.end = null

    wowtion.lots.forEach((lot) => {
      lot.start = null
      lot.end = null
    })
  },
}
