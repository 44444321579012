import state from './state'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

const wowtionStore = {
  state: state(),
  mutations,
  getters,
  actions,
}

export default wowtionStore
