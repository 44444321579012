export default {
  get: (value) => {
    const data = localStorage.getItem(value)
    if (!data) {
      return null
    }

    const item = JSON.parse(data)
    if (item.ttl && Date.now() > item.ttl) {
      localStorage.removeItem(value)
      return null
    }

    return item.value
  },
  set: (item, value, ttl = null) => {
    const data = { value }
    data.ttl = ttl ? Date.now() + (ttl * 1000) : null

    localStorage.setItem(item, JSON.stringify(data))
  },
  remove: (value) => {
    localStorage.removeItem(value)
  },
}
