import errorHandler from '@/helpers/errorHandler'
//TODO вынести сервис

export default {
  async get(page = 1, orientations = 'squarish') {
    try {
      const response = await fetch('https://api.unsplash.com/photos/random/?count=10', {
        headers: {
          Authorization: 'Client-ID cuVS7RXYdsX6_F_YLGKxENrHN6XulTM4Hx4iqI-HGLs',
        },
      })
      const data = await response.json()
      return data?.map((photo) => ({
        ...photo,
        src: {
          ...photo.urls,
          medium: photo.urls.regular,
          large: photo.urls.regular,
        },
      }))
    } catch (e) {
      errorHandler(e)
      return []
    }
  },
  async search(searchText, orientations, page = 1) {
    try {
      const response = await fetch(
        `https://api.unsplash.com/search/collections?query=${searchText.toString()}&orientation=${orientations}&per_page=8&page=${page}`,
        {
          headers: {
            Authorization: 'Client-ID cuVS7RXYdsX6_F_YLGKxENrHN6XulTM4Hx4iqI-HGLs',
          },
        },
      )
      const data = await response.json()
      return data?.results?.map((photo) => ({
        ...photo,
        src: {
          ...photo.cover_photo.urls,
          medium: photo.cover_photo.urls.regular,
          large: photo.cover_photo.urls.regular,
        },
      }))
    } catch (e) {
      errorHandler(e)
      return []
    }
  },
}
