import axios from 'axios'

export default {
  install: () => {
    axios.defaults.withCredentials = true
    axios.defaults.timeout = 10000
    axios.defaults.baseURL = process.env.VUE_APP_API_URL ?? ''

    axios.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem('AccessToken')
      if (accessToken && accessToken !== 'undefined') {
        config.headers = { Authorization: `Bearer ${JSON.parse(accessToken)}` }
      }
      return config
    })
  },
}
