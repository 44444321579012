import { generateCsv, download } from 'export-to-csv'

const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Участники',
  filename: 'Участники',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
}

export default (leads) => {
  const csv = generateCsv(options)(leads)
  download(options)(csv)
}
