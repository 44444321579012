import pay from '@/utils/pay'
import {useStore} from 'vuex'
import {computed} from 'vue'

export default function useCreatePayment() {
  const store = useStore()
  const user = computed(() => store.state.userStore.user)

  return async (tariff, onSuccess) => {
    // return

    // if (user.value?.readOnly?.cardLastFour) {
    //   store.commit('setLoading', true)
    //   await store.dispatch(userActionTypes.payByToken, { tariff, wowtionId: wowtion.value.id })
    //   await onSuccess()
    // } else {
    //
    // }
    const options = {
      description: 'Оплата регистраций',
      amount: +tariff.priceRu,
      userId: user.value.id,
      comment: `Wowtion: Оплатить регистрации (${tariff.amount} шт)`,
      email: user.value.email,
      paymentType: 'lot payment',
    }

    await pay(options, onSuccess)
  }
}
