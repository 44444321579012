export default {
  create: '[WOW] Create',
  getWowtions: '[WOW] Get wowtions',
  getWowtion: '[WOW] Get wowtion',
  getWinners: '[WOW] Get winners',
  deleteWowtion: '[WOW] Delete wowtion',
  archiveWowtion: '[WOW] Archive wowtion',
  getArchivesWowtions: '[WOW] Get archives wowtions',
  copyWowtion: '[WOW] Copy wowtion',
  save: '[WOW] Save',
  check: '[WOW] Check',
  launch: '[WOW] Launch',
  unlock: '[WOW] Unlock',
  getLeads: '[WOW] Get leads',
  getLeadsByWowtionId: '[WOW] Get leads by wowtion id',
  removeLead: '[WOW] Remove lead',
}
