export default async (options, onSuccess) => {
  try {
    const payKeeperOpts = {
      currency: 'RUB', //валюта
      // test: 1, // 1 - test
      merchant_fields: {
        wowtionId: options.wowtionId,
        userId: options.userId, //идентификатор плательщика (необязательно)
        paymentType: options.paymentType,
      },
      widget_key: process.env.VUE_APP_PAYMENTS_PUBLIC_ID,
      amount: options.amount * 100, //сумма
      description: options.description,
      payment_method: 'card',
      user_email: options.email,
      merchant_payment_id: options.billId
    }

    let M = new Mixplat(payKeeperOpts)
    M.build()

    M.setSuccessCallback(function(o, i){
      M.closeModal()
      if (onSuccess) onSuccess(o, i)
    })

    M.setFailCallback(function(o, i){
      console.log('fail')
    })

    M.setCloseCallback(function(o, i){
      if (onSuccess) onSuccess(o, i)
    })
  } catch (e) {
    console.log(e)
  }

}
