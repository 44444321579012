import router from '../router'
import store from '../store'

export default async () => {
  const lastRoutName = store.state.lastRoutName
  try {
    if (lastRoutName) {
      await router.push({ name: lastRoutName })
    } else await router.go(-1)
  } catch (e) {
    await router.go(-1)
  }
}
