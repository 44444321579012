const initState = () => {
  return {
    wowtion: {
      paymentSettings: {},
    },
    wowtionList: [],
    errors: [],
    winners: [],
    leads: [],
  }
}

export default initState
